<style scoped>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}

.con-nav-footer {
  padding-left: 20rem;
}
.part {
  width: 240px;
}
.wage {
  width: 200px;
}
.number {
  width: 100px;
  text-align: right;
}
.Dc {
  width: 80px;
}
.action {
  width: 40px;
}
.numberP {
  width: 80px;
  text-align: right;
}
.price {
  width: 100px;
  text-align: right;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "ใบเสนอราคาขายรถ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      // tableData: tableData,

      title: "ใบเสนอราคาขายรถ",
      items: [
        {
          text: "ฝ่ายขาย",
        },
        {
          text: "ใบเสนอราคาขายรถ",
          href: "/d-product-sell",
        },
        {
          text: "แก้ไขใบเสนอราคาขายรถ",
          active: true,
        },
      ],
      brand: "",
      qnDcPrice: 0,
      // branchId:'',
      qnDate: "",
      qnDetails: [],
      part: "",
      dcPerAccy: 0,
      amountAccy: 1,
      nameState: null,
      typeQnDetails: "ACCY",
      qnVatPer: 0.0,
      qnDcPer: 0,
      qnNetPrice: 0,
      qnVatPrice: 0,
      qnGrandTotalPrice: 0,
      resalert: "",

      branchIdSearch: "",
      overlayFlag: false,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,

      userBranchId: "",
      branchId: "",
      //   payMethCode: "",
      interiorCode: "",
      manageBranch: "",
      shortNameEn: "",
      shortNameTh: "",
      nameTh: "",
      dismissSecs: 3,
      dismissCountDown: 0,

      nameEn: "",
      payMethod: "",
      submitform: false,
      errormessage: "กรุณาตรวจสอบข้อมูลอีกครั้ง",

      userBranch: "",
      rowMasterBrands: [],
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      branch: "",
      rowProducts: [],
      rowsCustomers: [],
      rowsFin: [],
      rowsAct: [],
      rowsCampiagn: [],
      rowsExColor: [],
      rowsInColor: [],
      rowsAccy: [],
      rowsExtra: [],
      rowSaleCondition: [
        {
          scId: "C",
          scName: "เงินสด",
        },
        {
          scId: "I",
          scName: "เช่าซื้อ / ผ่อน",
        },
        {
          scId: "CD",
          scName: "สด / เครดิต",
        },
        {
          scId: "OI",
          scName: "เช่าซื้อสถาบันอื่น ๆ",
        },
      ],
      fieldProds:[
        {
          key: "prodCode",
          label: "รหัสสินค้า",
        },
        {
          key: "nameTh",
          label: "ชื่อสินค้า",
        },
        {
          key: "nameEn",
          label: "ชื่อสินค้า (อังกฤษ)",
        },
        {
          key: "vehicleBrandNameEn",
          label: "ยี่ห้อ",
        },
        {
          key: "price",
          label: "ราคา",
        }
      ],

      activity: "",

      qnAccy: [],
      qnPm: [],
      qnOth: [],

      ttAccy: 0,
      ttPm: 0,
      ttOTH: 0,

      totalPricex: 0,
      qnTotalPrice: 0,
      qnPartTotalPrice: 0,
      qnPartDcPer: 0,
      qnPartDcPrice: 0,
      qnPartNetPrice: 0,
      qnExtraTotalPrice: 0,
      qnExtraDcPer: 0,
      qnExtraDcPrice: 0,
      qnExtraNetPrice: 0,

      vehicleTotalPrice: 0,
      vehicleDcPer: 0,
      vehicleDcPrice: 0,
      vehicleNetPrice: 0,
      partTotalPrice: 0,
      partDcPer: 0,
      partDcPrice: 0,
      partNetPrice: 0,
      extraTotalPrice: 0,
      extraDcPer: 0,
      extraDcPrice: 0,
      extraNetPrice: 0,
      totalPrice: 0,
      dcPer: 0,
      dcPrice: 0,
      netPrice: 0,
      vatPer: 0,
      vatPrice: 0,
      grandTotalPrice: 0,
      saleCondition: "",
      downPayment: 0,
      totalFinancing: 0,
      fiId: "",
      interest: 0,
      numPeriods: 0,
      installment: 0,
      note: "",
      prodId: "",
      actId: "",
      exteriorColorId: "",
      interiorColorId: "",
      campaignId: "",
      statusQn: "",
      accyCode: "",
      partQnDt: [],
      partQnDtSum: [],
      exCode: "",
      selectedExtra: "",
      ex: "",
      disabledWsc: "",
      searchProdcode:"",
      searchNameProd:"",

    };
  },
  validations: {
    qnDate: {
      required,
    },
    branch: {
      required,
    },
    nameTh: {
      required,
    },
    nameEn: {},
    manageBranch: {},
    shortNameTh: {},
    shortNameEn: {},
    prodId: {
      required,
    },
    activity: {
      required,
    },
    exteriorColorId: {
      required,
    },
    interiorColorId: {
      required,
    },
    campaignId: {},
    vehicleTotalPrice: {
      required,
    },
    vehicleDcPer: {
      required,
    },
    vehicleDcPrice: {
      required,
    },
    vehicleNetPrice: {
      required,
    },
    downPayment: {
      required,
    },
    totalFinancing: {
      required,
    },
    saleCondition: {
      required,
    },
    numPeriods: {
      required,
    },
    installment: {
      required,
    },
    interest: {
      required,
    },
    fiId: {
      required,
    },
    note: {},
    qnVatPer: {
      required,
    },
    qnDcPer: {
      required,
    },
    qnNetPrice: {
      required,
    },
    qnVatPrice: {
      required,
    },
    qnGrandTotalPrice: {
      required,
    },
    partTotalPrice: {
      required,
    },
    partDcPer: {
      required,
    },
    partDcPrice: {
      required,
    },
    partNetPrice: {
      required,
    },
    extraTotalPrice: {
      required,
    },
    extraDcPer: {
      required,
    },
    extraDcPrice: {
      required,
    },
    extraNetPrice: {
      required,
    },
    ttAccy: {
      required,
    },
    ttOTH: {
      required,
    },
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {},
  created() {
    // this.getMasterBrand();
    this.showQn();
    this.showQnDetails();
    this.getLocalData();
    this.getProduct();
    this.getCustomer();
    this.getFin();
    this.getActivity();
    this.getCampaign();
    this.getExColor();
    this.getInColor();
    this.getAccy();
    this.getExtra();
    this.calTotal();
  },
  methods: {
    PutStatusQn() {
      useNetw
        .put("api/sale-quotation/submit", {
          qnId: this.$route.params.qnId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.showQn();
          this.showQnDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.data),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalEditPartRT.hide();
        });
    },
    confirmPutstatusQn() {
      Swal.fire({
        title: "ต้องการยืนยันใบเสนอราคาขายใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.PutStatusQn();
        }
      });
    },
    changeSc(scId) {
      if (scId != "I") {
        // // // console.log(scId);
        this.disabledWsc = "dis";
        this.fiId = [];
        this.downPayment = 0.0;
        this.qnVatPer = 0.0;
        this.interest = 0.0;
        (this.numPeriods = 0), (this.installment = 0.0);
        this.totalFinancing = 0.0;
      } else {
        this.disabledWsc = "";
      }
    },
    filterFi(id) {
      let result;
      this.rowsFin.filter(function(item) {
        if (item.fiId == id) {
          // // // console.log(item.nameTh);
          // this.saleCondition.scName = item.scName
          // // // console.log('Fi',item);
          result = item.nameTh;
        }
      });
      return result;
    },
    filterCdt(id) {
      let result;
      this.rowSaleCondition.filter(function(item) {
        if (item.scId == id) {
          // // // console.log(item.scName);
          // this.saleCondition.scName = item.scName
          result = item.scName;
        }
      });
      return result;
    },
    changeType(type) {
      this.typeQnDetails = type;
      // // console.log(this.typeQnDetails);
    },
    postEx(ex) {
      // let arr = [];
      // this.qnDetails.forEach((item) => {
      //   arr.push(item.extraId);
      // });
      // if (!arr.includes(ex.extraId)) {
      //   this.qnDetails.push(ex);
      // }

      this.calTotal();

      const formDataPart = new FormData();
      formDataPart.append("qnId", this.$route.params.qnId);
      // formDataPart.append("partId", null);
      formDataPart.append("extraId", ex.extraId);
      formDataPart.append("amount", this.amountAccy);
      formDataPart.append("pricePerOne", ex.price);
      formDataPart.append("totalPrice", ex.price * this.amountAccy);
      formDataPart.append("dcPrice", ex.price * (this.dcPerAccy / 100));
      formDataPart.append("dcPer", this.dcPerAccy);
      formDataPart.append("netPrice", ex.price * this.amountAccy);
      formDataPart.append("vatPer", this.vatPer);
      formDataPart.append("vatPrice", 0);
      formDataPart.append("grandTotalPrice", ex.price * this.amountAccy);

      formDataPart.append("type", "OTH");

      formDataPart.append("qnPartTotalPrice", this.ttAccy);
      formDataPart.append("qnPartDcPer", this.partDcPer);
      formDataPart.append("qnPartDcPrice", this.partDcPrice);
      formDataPart.append("qnPartNetPrice", this.partNetPrice);
      formDataPart.append(
        "qnExtraTotalPrice",
        this.extraTotalPrice + ex.price * this.amountAccy
      );
      formDataPart.append("qnExtraDcPer", this.extraDcPer);
      formDataPart.append("qnExtraDcPrice", this.extraDcPrice);
      formDataPart.append(
        "qnExtraNetPrice",
        this.extraNetPrice + ex.price * this.amountAccy
      );

      formDataPart.append("qnTotalPrice", this.qnTotalPrice);
      formDataPart.append("qnDcPer", this.qnDcPer);
      formDataPart.append("qnDcPrice", this.qnDcPrice);
      formDataPart.append("qnNetPrice", this.qnNetPrice);
      formDataPart.append("qnVatPer", this.qnVatPer);
      formDataPart.append("qnVatPrice", this.qnVatPrice);

      formDataPart.append(
        "qnGrandTotalPrice",
        parseFloat(this.qnGrandTotalPrice)
      );

      useNetw
        .post("api/sale-quotation/detail/store", formDataPart)
        .then((response) => {
          this.$refs.modalAmoutEx.hide();
          this.showAlert(response.data.message);
          this.showQnDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs["modalAmout"].hide();
          // this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    calAll() {
      this.qnDetails.forEach((item) => {
        if (item.type == "ACCY") {
          this.ttAccy += parseFloat(item.netPrice);
          // // // console.log('this.ttAccy',this.ttAccy);
        } else if (item.type == "PM") {
          this.ttPm = 0;
        } else {
          this.ttOTH += parseFloat(item.netPrice);
          // // // console.log('this.ttOTH',this.ttOTH);
        }
        // // // console.log("ACCY=>", this.qnAccy);
      });
    },
    delAccy(rowRT, index) {
      // this.selectDataPO = rowsRC.item;

      this.qnDetails.splice(index, 1);
      this.calTotal();
      // // // console.log('this.qnPartDcPer[0]====',this.qnPartDcPer);
      // // // // // console.log('RT ====> 0',rowRT);
      useNetw
        .delete("api/sale-quotation/detail/delete", {
          data: {
            qndId: rowRT.qndId,
            // partId: rowRT.partId,

            qnPartTotalPrice: this.partTotalPrice,
            qnPartDcPer: this.partDcPer,
            qnPartDcPrice: this.partDcPrice,
            qnPartNetPrice: this.partNetPrice,
            qnExtraTotalPrice: this.qnExtraTotalPrice,
            qnExtraDcPer: this.extraDcPer,
            qnExtraDcPrice: this.extraDcPrice,
            qnExtraNetPrice: this.extraNetPrice,
            qnTotalPrice: this.qnTotalPrice,
            qnDcPer: this.qnDcPer,
            qnDcPrice: this.qnDcPrice,
            qnNetPrice: this.qnNetPrice,
            qnVatPer: this.qnVatPer,
            qnVatPrice: this.qnVatPrice,
            qnGrandTotalPrice: parseFloat(this.qnGrandTotalPrice),
          },
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.showQnDetails();
          // this.getTotalPriceRT;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },
    ConfirmDelAccy(rowsAccy, index) {
      // // // // console.log(rowsRT[index]);
      // // // // // console.log('DEL==>',rowsRC.item.poId);
      Swal.fire({
        title: "ต้องการลบใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: "ยืนยันการลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.delAccy(rowsAccy[index], index);
        }
      });
    },
    calculatePQnDt(part) {
      let totalPriceCal =
        parseFloat(part.pricePerOne) * parseFloat(part.amount);
      if (!isNaN(totalPriceCal)) {
        part.totalPrice = totalPriceCal.toFixed(2);
      }
      var DcPricecal = 0;

      DcPricecal = parseFloat(part.totalPrice) * parseFloat(part.dcPer / 100);
      if (!isNaN(DcPricecal)) {
        part.dcPrice = DcPricecal.toFixed(2);
      }

      let calNetPrice = 0;

      calNetPrice = parseFloat(part.totalPrice) - parseFloat(part.dcPrice);
      if (!isNaN(calNetPrice)) {
        part.netPrice = calNetPrice.toFixed(2);
      }

      let calVatPrice = 0;

      calVatPrice = part.netPrice * parseFloat(this.vatPer / 100);
      if (!isNaN(calVatPrice)) {
        part.vatPrice = calVatPrice.toFixed(2);
      }

      let calGrandTotalPrice =
        parseFloat(part.netPrice) + parseFloat(part.vatPrice);
      if (!isNaN(calGrandTotalPrice)) {
        part.grandTotalPrice = calGrandTotalPrice.toFixed(2);
      }

      this.partQnDt = part;

      this.qnDetails.forEach((item) => {
        if (item.type == "ACCY") {
          this.ttAccy += parseFloat(item.netPrice);
          // // // console.log('this.ttAccy',this.ttAccy);
        } else if (item.type == "PM") {
          this.ttPm = 0;
        } else {
          this.ttOTH += parseFloat(item.netPrice);
          // // // console.log('this.ttOTH',this.ttOTH);
        }
        // // // console.log("ACCY=>", this.qnAccy);
      });

      this.calTotal();
    },

    calculateQnDt(part) {
      let totalPriceCal =
        parseFloat(part.pricePerOne) * parseFloat(this.amountAccy);
      if (!isNaN(totalPriceCal)) {
        part.totalPrice = totalPriceCal.toFixed(2);
      }
      var DcPricecal = 0;

      DcPricecal = parseFloat(part.totalPrice) * parseFloat(part.dcPer / 100);
      if (!isNaN(DcPricecal)) {
        part.dcPrice = DcPricecal.toFixed(2);
      }

      let calNetPrice = 0;

      calNetPrice = parseFloat(part.totalPrice) - parseFloat(part.dcPrice);
      if (!isNaN(calNetPrice)) {
        part.netPrice = calNetPrice.toFixed(2);
      }

      let calVatPrice = 0;

      calVatPrice = part.netPrice * parseFloat(this.vatPer / 100);
      if (!isNaN(calVatPrice)) {
        part.vatPrice = calVatPrice.toFixed(2);
      }

      let calGrandTotalPrice =
        parseFloat(part.netPrice) + parseFloat(part.vatPrice);
      if (!isNaN(calGrandTotalPrice)) {
        part.grandTotalPrice = calGrandTotalPrice.toFixed(2);
      }

      this.partQnDt = part;
      this.calTotal();
    },
    calTotal() {
      this.ttAccy = 0;
      this.ttOTH = 0;

      this.qnDetails.forEach((item) => {
        if (item.type == "ACCY") {
          this.ttAccy += parseFloat(item.netPrice);
          // // console.log("ttAccy", this.ttAccy);
        } else if (item.type == "PM") {
          this.ttPm = 0;
        } else {
          this.ttOTH += parseFloat(item.netPrice);
        }
      });
      this.partTotalPrice = parseFloat(this.ttAccy);
      this.extraTotalPrice = parseFloat(this.ttOTH);

      this.vehicleDcPrice =
        parseFloat(this.vehicleTotalPrice) *
        parseFloat(this.vehicleDcPer / 100);
      this.vehicleNetPrice =
        parseFloat(this.vehicleTotalPrice) - parseFloat(this.vehicleDcPrice);
      this.partDcPrice =
        parseFloat(this.ttAccy) * parseFloat(this.partDcPer / 100);
      this.partNetPrice =
        parseFloat(this.ttAccy) - parseFloat(this.partDcPrice);
      this.extraDcPrice =
        parseFloat(this.ttOTH) * parseFloat(this.extraDcPer / 100);
      this.extraNetPrice =
        parseFloat(this.ttOTH) - parseFloat(this.extraDcPrice);

      var qnTotalPrice, qnDcPrice, qnNetPrice, qnVatPrice, qnGrandTotalPrice;
      // // console.log("vehicleNetPrice", this.vehicleNetPrice);

      qnTotalPrice =
        parseFloat(this.vehicleNetPrice) +
        parseFloat(this.partNetPrice) +
        parseFloat(this.extraNetPrice);
      // // console.log("qnTotalPrice", qnTotalPrice);
      this.qnTotalPrice = parseFloat(qnTotalPrice);
      qnDcPrice = parseFloat(this.qnDcPer / 100) * parseFloat(qnTotalPrice);
      this.qnDcPrice = parseFloat(qnDcPrice);
      qnNetPrice = parseFloat(qnTotalPrice) - parseFloat(this.qnDcPrice);
      this.qnNetPrice = parseFloat(qnNetPrice);
      qnVatPrice = parseFloat(this.qnVatPer / 100) * parseFloat(qnNetPrice);
      this.qnVatPrice = parseFloat(qnVatPrice);
      qnGrandTotalPrice = parseFloat(qnNetPrice) + parseFloat(qnVatPrice);
      this.qnGrandTotalPrice = parseFloat(qnGrandTotalPrice);

      this.totalFinancing =
        parseFloat(this.qnGrandTotalPrice) - parseFloat(this.downPayment);

      this.changeSc(this.saleCondition.scId);

      // // // console.log('ttAccy in func =>', this.ttAccy);
    },
    putAccy(
      qndid,
      partId,
      sellPrice,
      amount,
      totalPrice,
      dcPer,
      dcPrice,
      netPrice,
      vatPer,
      vatPrice,
      grandTotalPrice,
      extraId
    ) {
      // this.amountRT = amount;
      // // console.log(
      //   qndid,
      //   partId,
      //   sellPrice,
      //   amount,
      //   totalPrice,
      //   dcPer,
      //   dcPrice,
      //   netPrice,
      //   vatPer,
      //   vatPrice,
      //   grandTotalPrice,
      //   extraId
      // );
      (this.amountAccy = amount), this.calTotal();
      useNetw
        .put("api/sale-quotation/detail/update", {
          qnId: this.$route.params.qnId,

          qndId: qndid,
          partId: parseInt(partId),
          amount: parseInt(amount),
          extraId: extraId,
          pricePerOne: sellPrice,
          totalPrice: totalPrice,
          dcPer: dcPer,
          dcPrice: dcPrice,
          netPrice: netPrice,
          vatPer: vatPer,
          vatPrice: vatPrice,
          grandTotalPrice: parseInt(amount) * sellPrice,
          type: this.typeQnDetails,
          // pickingAmount: this.selectDataRT.pickingAccruedAmount - this.amountRT,
          // pickingAccruedAmount: this.selectDataRT.pickingAccruedAmount - this.amountRT,
          qnPartTotalPrice: this.partTotalPrice,
          qnPartDcPer: this.partDcPer,
          qnPartDcPrice: this.partDcPrice,
          qnPartNetPrice: this.partNetPrice,
          qnExtraTotalPrice: this.extraTotalPrice,
          qnExtraDcPer: this.extraDcPer,
          qnExtraDcPrice: this.extraDcPrice,
          qnExtraNetPrice: this.extraNetPrice,

          qnTotalPrice: this.qnTotalPrice,
          qnDcPer: this.qnDcPer,
          qnDcPrice: this.qnDcPrice,
          qnNetPrice: this.qnNetPrice,
          qnVatPer: this.qnVatPer,
          qnVatPrice: this.qnVatPrice,
          qnGrandTotalPrice: parseFloat(this.qnGrandTotalPrice),
        })
        .then((response) => {
          this.showAlert(response.data.message);

          this.showQnDetails;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalEditPartRT.hide();
        });
    },

    postAccy(part) {
      let arr = [];
      this.qnDetails.forEach((item) => {
        arr.push(item.partId);
      });
      if (!arr.includes(part.partId)) {
        this.qnDetails.push(part);
      }

      this.calculateQnDt(part);
      // // console.log("part.totalPrice=>", part);

      part.partId = this.selectedPart.partId;
      part.nameTh = this.selectedPart.nameTh;
      part.pricePerOne = this.selectedPart.sellPrice;
      part.totalPrice = this.selectedPart.sellPrice * this.amountAccy;
      // // console.log("TT", part.totalPrice);
      part.netPrice;

      this.calTotal();

      const formDataPart = new FormData();
      formDataPart.append("qnId", this.$route.params.qnId);
      formDataPart.append("partId", part.partId);
      formDataPart.append("amount", this.amountAccy);
      formDataPart.append("pricePerOne", part.pricePerOne);
      formDataPart.append("totalPrice", part.totalPrice);
      formDataPart.append("dcPrice", part.totalPrice * (this.dcPerAccy / 100));
      formDataPart.append("dcPer", this.dcPerAccy);
      formDataPart.append("netPrice", part.totalPrice);
      formDataPart.append("vatPer", this.vatPer);
      formDataPart.append("vatPrice", part.vatPrice);
      formDataPart.append("grandTotalPrice", part.totalPrice);

      formDataPart.append("type", this.typeQnDetails);

      formDataPart.append(
        "qnPartTotalPrice",
        this.partTotalPrice + part.totalPrice
      );
      formDataPart.append("qnPartDcPer", this.partDcPer);
      formDataPart.append("qnPartDcPrice", this.partDcPrice);
      formDataPart.append(
        "qnPartNetPrice",
        this.partNetPrice + part.totalPrice
      );
      formDataPart.append("qnExtraTotalPrice", this.extraTotalPrice);
      formDataPart.append("qnExtraDcPer", this.extraDcPer);
      formDataPart.append("qnExtraDcPrice", this.extraDcPrice);
      formDataPart.append("qnExtraNetPrice", this.extraNetPrice);
      formDataPart.append("grandTotalPrice", this.extraNetPrice);

      formDataPart.append("qnTotalPrice", this.qnTotalPrice);
      formDataPart.append("qnDcPer", this.qnDcPer);
      formDataPart.append("qnDcPrice", this.qnDcPrice);
      formDataPart.append("qnNetPrice", this.qnNetPrice);
      formDataPart.append("qnVatPer", this.qnVatPer);
      formDataPart.append("qnVatPrice", this.qnVatPrice);

      formDataPart.append(
        "qnGrandTotalPrice",
        parseFloat(this.qnGrandTotalPrice)
      );

      useNetw
        .post("api/sale-quotation/detail/store", formDataPart)
        .then((response) => {
          this.showAlert(response.data.message);
          this.showQnDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs["modalAmout"].hide();
          // this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    showModal(option) {
      if (option != null) {
        this.selectedPart = option;
        this.part = option;
        this.part.partCode = option.partCode;
        this.part.pricePerOne = option.sellPrice;
        this.part.totalPrice = option.sellPrice;
        this.part.netPrice = option.sellPrice;
        this.part.nameTh = option.nameTh;
        this.part.partId = option.partId;
        this.$refs.modalAmout.show(option);
      }
    },

    showModalEx(option) {
      if (option != null) {
        this.selectedExtra = option;
        this.ex = option;
        this.ex.partCode = option.partCode;
        this.ex.pricePerOne = option.sellPrice;
        this.ex.totalPrice = option.sellPrice;
        this.ex.netPrice = option.sellPrice;
        this.ex.nameTh = option.nameTh;
        this.ex.partId = option.partId;
        this.$refs.modalAmoutEx.show(option);
      }
    },
    showQnDetails() {
      this.qnAccy = [];
      this.qnPm = [];
      this.qnOth = [];
      this.overlayFlag = true;
      useNetw
        .get("api/sale-quotation/detail", {
          params: {
            qnId: this.$route.params.qnId,
          },
        })
        .then((response) => {
          // // console.log("qn Details =>", response.data.data);
          this.qnDetails = response.data.data;
          this.qnDetails.forEach((item) => {
            if (item.type == "ACCY") {
              this.qnAccy.push(item);
            } else if (item.type == "PM") {
              this.qnPm.push(item);
            } else if (item.type == "OTH") {
              this.qnOth.push(item);
            }
            // // // console.log("ACCY=>", this.qnAccy);
          });
          this.calTotal();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
          this.calTotal();
        });
    },
    showQn() {
      // // // console.log(this.$route.params.qnId);
      this.overlayFlag = true;
      useNetw
        .get("api/sale-quotation/show", {
          params: {
            qnId: this.$route.params.qnId,
          },
        })
        .then((response) => {
          this.statusQn = response.data.data.status;
          // // console.log("Show =>", response.data.data);

          this.branch = {
            branchId: response.data.data.branchId,
            nameTh: response.data.data.branchName,
          };
          (this.nameTh = {
            cusId: response.data.data.cusId,
            nameTh: response.data.data.customerNameTh,
            familyNameTh: response.data.data.customerFamilyNameTh,
          }),
            (this.qnDate = response.data.data.qnDate);
          this.activity = {
            actId: response.data.data.actId,
            nameTh: response.data.data.activityNameTh,
          };
          this.prodId = {
            prodId: response.data.data.prodId,
            prodCode: response.data.data.brandNameEn,
            nameTh: response.data.data.productNameTh,
            nameEn: response.data.data.productNameEn,
          };
          this.exteriorColorId = {
            exteriorColorId: response.data.data.exteriorColorId,
            nameTh: response.data.data.exteriorColorNameTh,
            nameEn: response.data.data.exteriorColorNameEn,
          };
          this.interiorColorId = {
            interiorColorId: response.data.data.interiorColorId,
            nameTh: response.data.data.interiorColorNameTh,
            nameEn: response.data.data.interiorColorNameEn,
          };
          this.campaignId = {
            campaignId: response.data.data.campaignId,
            nameTh:
              response.data.data.campaignNameTh == null
                ? "-"
                : response.data.data.campaignNameTh,
            nameEn:
              response.data.data.campaignNameEn == null
                ? "-"
                : response.data.data.campaignNameEn,
          };
          this.vehicleTotalPrice = response.data.data.vehicleTotalPrice;
          this.vehicleDcPer = response.data.data.vehicleDcPer;
          this.vehicleDcPrice = response.data.data.vehicleDcPrice;
          this.vehicleNetPrice = response.data.data.vehicleNetPrice;

          this.saleCondition = {
            scId: response.data.data.saleCondition,
            scName: this.filterCdt(response.data.data.saleCondition),
          };

          this.fiId = {
            fiId: response.data.data.fiId,
            nameTh: response.data.data.financialInstitutionNameTh,
          };
          this.downPayment = response.data.data.downPayment;
          this.totalFinancing = response.data.data.totalFinancing;
          // this.fiId = {
          //   fiId: response.data.data.fiId,
          //   nameTh: response.data.data.fiId,
          // };
          this.interest = response.data.data.interest;
          (this.numPeriods = response.data.data.numPeriods),
            (this.installment = response.data.data.installment),
            (this.note =
              response.data.data.note == null ? "" : response.data.data.note);

          this.partDcPer = response.data.data.partDcPer;
          this.extraDcPer = response.data.data.extraDcPer;

          // // console.log(response.data.data);
          this.changeSc(this.saleCondition.scId);

          this.calTotal();
          this.overlayFlag = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.calTotal();
          this.overlayFlag = false;
        });
    },
    setProduct(vehicle) {
      this.vehicleTotalPrice = parseInt(vehicle.price);
      this.calTotal();
    },
    // calProd() {
    //   var dcPrice;
    //   var netPrice;
    //   var totalFinancing;
    //   var installments;

    //   if (!isNaN()) {
    //     // // // console.log("No");
    //   } else {
    //     dcPrice =
    //       this.vehicleTotalPrice * (parseFloat(this.vehicleDcPer) / 100);
    //     netPrice = this.vehicleTotalPrice - dcPrice;
    //     totalFinancing = netPrice - parseFloat(this.downPayment);
    //     installments =
    //       (totalFinancing / this.numPeriods) * (1 + this.interest / 100);

    //     this.vehicleDcPrice = dcPrice;
    //     this.vehicleNetPrice = netPrice;
    //     this.totalFinancing = totalFinancing;
    //     this.installment = installments.toFixed(2);
    //   }
    // },
    calProd() {
      var dcPrice;
      var netPrice;
      var totalFinancing;
      var installments;
      var dPerYear;

      if (!isNaN()) {
        //console.log("No");
      } else {
        dcPrice =
          this.vehicleTotalPrice * (parseFloat(this.vehicleDcPer) / 100);
        netPrice = this.vehicleTotalPrice - dcPrice;
        totalFinancing = netPrice - parseFloat(this.downPayment);
        dPerYear = ((totalFinancing * (1 + this.interest / 100)) - totalFinancing) * (this.numPeriods/12);
        // dPerYear = ((totalFinancing * (1 + this.interest / 100)) /  (this.numPeriods/12));
        
        installments =
          // (totalFinancing / this.numPeriods * (1 + this.interest / 100));
          parseFloat(dPerYear + totalFinancing) / this.numPeriods
        
        
        // console.log(installments);
        this.vehicleDcPrice = dcPrice;
        this.vehicleNetPrice = netPrice;
        this.totalFinancing = totalFinancing;
        this.installment = installments.toFixed(2);
      }
    },
    customLabelEx({ extraCode, nameTh, price }) {
      return `[${extraCode == null ? '' : extraCode}]  ${nameTh} : (${price} บาท)`;
    },
    customLabelProduct({ prodCode, nameEn }) {
      return `[${prodCode == null ? '' : prodCode}]  ${nameEn}`;
    },
    customLabelCustomer({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh == null ? '' : familyNameTh}`;
    },
    customLabelAccy({ partCode, nameTh }) {
      return `[${partCode != null ? " " + partCode : ""}] - ${
        nameTh != null ? nameTh : ""
      }`;
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      this.branchId = user.branchId;
      // // // // console.log(this.localDataBranch);
    },
    /**
     * Search the table data with search input
     */

    tooltipForm() {
      // // // // console.log(this.$v)
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitSalq();
      }
    },

    getInColor() {
      this.overlayFlag = true;
      //   // // // console.log('Success ');
      useNetw
        .get("api/sale-quotation/branch-interior-color", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsInColor = response.data.data;
          // // // // console.log("InC=>", this.rowsInColor);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getExtra() {
      this.overlayFlag = true;
      //   // // // console.log('Success ');
      useNetw
        .get("api/sale-quotation/branch-extra", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsExtra = response.data.data;
          // // console.log("Extra=>", this.rowsExtra);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getExColor() {
      this.overlayFlag = true;
      //   // // // console.log('Success ');
      useNetw
        .get("api/sale-quotation/branch-exterior-color", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsExColor = response.data.data;
          // // // // console.log("ExC=>", this.rowsExColor);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getCampaign() {
      this.overlayFlag = true;
      //   // // // console.log('Success ');
      useNetw
        .get("api/sale-quotation/branch-campaign", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsCampiagn = response.data.data;
          // // // // console.log("CAM=>", this.rowsCampiagn);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getActivity() {
      this.overlayFlag = true;
      //   // // // console.log('Success ');
      useNetw
        .get("api/sale-quotation/branch-activity", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsAct = response.data.data;
          // // // // console.log("ACT=>", this.rowsAct);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getFin() {
      this.overlayFlag = true;
      //   // // // console.log('Success ');
      useNetw
        .get("api/sale-quotation/branch-financial-institution", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsFin = response.data.data;
          // // // console.log("FIN=>", this.rowsFin);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getCustomer() {
      this.overlayFlag = true;
      //   // // // console.log('Success ');
      useNetw
        .get("api/sale-quotation/branch-customer", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsCustomers = response.data.data;
          // // // // console.log("CUS=>", this.rowsCustomers);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getAccy() {
      this.overlayFlag = true;
      //   // // // console.log('Success ');
      useNetw
        .get("api/sale-quotation/branch-part", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsAccy = response.data.data;
          // // // console.log("rowsAccy=>", this.rowsAccy);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getProduct() {
      this.overlayFlag = true;
      //   // // // console.log('Success ');
      useNetw
        .get("api/sale-quotation/branch-product		", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchId,
            nameTh: this.searchNameProd,
            prodCode: this.searchProdcode
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowProducts = response.data.data;
          // // console.log(this.rowProducts);
          // // // // console.log("PC=>", this.rowProducts);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    submitSalq: function() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .put("api/sale-quotation/update", {
          qnId: this.$route.params.qnId,
          branchId: this.branch.branchId,
          cusId: this.nameTh.cusId,
          qnDate: this.qnDate,
          vehicleTotalPrice: this.vehicleTotalPrice,
          vehicleDcPer: this.vehicleDcPer,
          vehicleDcPrice: this.vehicleDcPrice,
          vehicleNetPrice: this.vehicleNetPrice,
          partTotalPrice: this.partTotalPrice,
          partDcPer: this.partDcPer,
          partDcPrice: this.partDcPrice,
          partNetPrice: this.partNetPrice,
          extraTotalPrice: this.extraTotalPrice,
          extraDcPer: this.extraDcPer,
          extraDcPrice: this.extraDcPrice,
          extraNetPrice: this.extraNetPrice,
          totalPrice: this.qnTotalPrice,
          dcPer: this.qnDcPer,
          dcPrice: this.qnDcPrice,
          netPrice: this.qnNetPrice,
          vatPer: this.qnVatPer,
          vatPrice: this.qnVatPrice,

          grandTotalPrice: parseFloat(this.qnGrandTotalPrice),
          saleCondition: this.saleCondition.scId,
          downPayment: this.downPayment,
          totalFinancing: this.totalFinancing,
          fiId: this.fiId.fiId,
          interest: this.interest,
          numPeriods: this.numPeriods,
          installment: this.installment,
          note: this.note,
          prodId: this.prodId.prodId,
          actId: this.activity.actId,
          exteriorColorId: this.exteriorColorId.exteriorColorId,
          interiorColorId: this.interiorColorId.interiorColorId,
          campaignId: this.campaignId.campaignId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "d-product-sell",
            // params: {
            //   qnId: response.data.qnId,
            // },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },

    popupModalProduct() {
      this.$refs["modalProd"].show();
    },
    onRowSelectedProd(data){
      // // console.log(data);
      this.vehicleTotalPrice = parseInt(data[0].price);
      this.calTotal();
      this.$refs["modalProd"].hide();
    }
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert
      class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
      :show="dismissCountDown"
      dismissible
      fade
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="float-end">{{ dismissCountDown }} วินาที...</p>
      <p>แจ้งเตือน</p>
      <hr />
      <p>{{ resalert }}</p>
    </b-alert>
    <b-overlay
      :show="overlayFlag"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <code>* </code>สาขา :<br />
                        <multiselect
                          :disabled="statusQn != 'DFT'"
                          v-model="branch"
                          :options="localData"
                          label="nameTh"
                          :class="{
                            'is-invalid': submitform && $v.branch.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.branch.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.branch.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code>ชื่อลูกค้า:
                        <multiselect
                          :disabled="statusQn != 'DFT'"
                          v-model="nameTh"
                          label="nameTh"
                          type="search"
                          :options="rowsCustomers"
                          :show-labels="false"
                          open-direction="bottom"
                          @search-change="getCustomer"
                          @remove="getCustomer"
                          :custom-label="customLabelCustomer"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.nameTh.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 p-0"><br>
                      <router-link :to="{ name: 'formcus' }"> &nbsp;
                      <button class="btn btn-primary" > เพิ่ม </button>
                      </router-link>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code>* </code>วันที่ :
                        <date-picker
                          :disabled="statusQn != 'DFT'"
                          v-model="qnDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          :class="{
                            'is-invalid': submitform && $v.qnDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.qnDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.qnDate.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code>* </code>กิจกรรมการขาย :
                        <multiselect
                          :disabled="statusQn != 'DFT'"
                          v-model="activity"
                          label="nameTh"
                          type="search"
                          :options="rowsAct"
                          open-direction="bottom"
                          placeholder="กิจกรรมการขาย"
                          :class="{
                            'is-invalid': submitform && $v.activity.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.activity.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.activity.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code>* </code>รหัสสินค้า :
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          :value="prodId.prodCode"
                        />
                        <div
                          v-if="submitform && $v.prodId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.prodId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <code>* </code>ชื่อสินค้า :
                        <multiselect
                          :disabled="statusQn != 'DFT'"
                          @input="setProduct(prodId)"
                          v-model="prodId"
                          label="nameEn"
                          type="search"
                          :options="rowProducts"
                          open-direction="bottom"
                          placeholder="ชื่อสินค้า"
                          :custom-label="customLabelProduct"
                          :class="{
                            'is-invalid': submitform && $v.prodId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.prodId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.prodId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 text-left">
                      &nbsp; <br />
                      <button
                        class="btn btn-primary"
                        @click="popupModalProduct()"
                      >
                        <i class="uil uil-search-plus"></i>
                      </button>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code>* </code>สีภายนอก :
                        <multiselect
                          :disabled="statusQn != 'DFT'"
                          v-model="exteriorColorId"
                          label="nameTh"
                          type="search"
                          :options="rowsExColor"
                          open-direction="bottom"
                          placeholder="สีภายนอก"
                          :class="{
                            'is-invalid':
                              submitform && $v.exteriorColorId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.exteriorColorId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.exteriorColorId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code>* </code>สีภายใน :
                        <multiselect
                          :disabled="statusQn != 'DFT'"
                          v-model="interiorColorId"
                          label="nameTh"
                          type="search"
                          :options="rowsInColor"
                          open-direction="bottom"
                          placeholder="สีภายใน"
                          :class="{
                            'is-invalid':
                              submitform && $v.interiorColorId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.interiorColorId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.interiorColorId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <code>* </code>แคมเปญ :
                        <multiselect
                          :disabled="statusQn != 'DFT'"
                          v-model="campaignId"
                          label="nameTh"
                          type="search"
                          :options="rowsCampiagn"
                          open-direction="bottom"
                          placeholder="แคมเปญ"
                          :class="{
                            'is-invalid': submitform && $v.campaignId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.campaignId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.campaignId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code>* </code>เงื่อนไขการขาย :
                        <multiselect
                          @input="changeSc(saleCondition.scId)"
                          :disabled="statusQn != 'DFT'"
                          v-model="saleCondition"
                          label="scName"
                          type="search"
                          :options="rowSaleCondition"
                          open-direction="bottom"
                          placeholder="เงื่อนไขการขาย"
                          :class="{
                            'is-invalid': submitform && $v.saleCondition.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.saleCondition.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.saleCondition.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        เงินดาวน์ (บาท) :
                        <input
                          :disabled="statusQn != 'DFT' || disabledWsc == 'dis'"
                          @change="calTotal()"
                          type="number"
                          style="text-align:right"
                          v-model="downPayment"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.downPayment.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.downPayment.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.downPayment.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        ยอดจัดไฟแนนซ์ (บาท) :
                        <input
                          :disabled="statusQn != 'DFT' || disabledWsc == 'dis'"
                          type="number"
                          style="text-align:right"
                          v-model="totalFinancing"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.totalFinancing.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.totalFinancing.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.totalFinancing.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        %ภาษี :
                        <input
                          :disabled="statusQn != 'DFT' || disabledWsc == 'dis'"
                          type="number"
                          style="text-align:right"
                          v-model="qnVatPer"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.qnVatPer.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.qnVatPer.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.qnVatPer.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        สถาบันการเงิน :
                        <multiselect
                          :disabled="statusQn != 'DFT' || disabledWsc == 'dis'"
                          v-model="fiId"
                          label="nameTh"
                          type="search"
                          :options="rowsFin"
                          open-direction="bottom"
                          placeholder="สถาบันการเงิน"
                          :class="{
                            'is-invalid': submitform && $v.fiId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.fiId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.fiId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        ดอกเบี้ย / เดือน :
                        <input
                          :disabled="statusQn != 'DFT' || disabledWsc == 'dis'"
                          type="number"
                          style="text-align:right"
                          v-model="interest"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.interest.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.interest.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.interest.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        จำนวนงวด :
                        <input
                          :disabled="statusQn != 'DFT' || disabledWsc == 'dis'"
                          @change="calProd()"
                          min="0"
                          type="number"
                          style="text-align:right"
                          v-model="numPeriods"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.numPeriods.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.numPeriods.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.numPeriods.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        ยอดชำระต่องวด (บาท):
                        <input
                          :disabled="statusQn != 'DFT' || disabledWsc == 'dis'"
                          type="number"
                          style="text-align:right"
                          v-model="installment"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.installment.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.installment.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.installment.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-8">
                      <div class="mb-3 position-relative">
                        หมายเหตุ :
                        <textarea
                          :disabled="statusQn != 'DFT'"
                          v-model="note"
                          class="form-control"
                          name="textarea"
                          rows="5"
                          :class="{
                            'is-invalid': submitform && $v.note.$error,
                          }"
                        ></textarea>
                        <div
                          v-if="submitform && $v.note.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.note.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12">
                        <b-button
                          :disabled="statusQn != 'DFT'"
                          class="btn float-end m-1"
                          variant="success"
                          @click="tooltipForm"
                        >
                          เสร็จสิ้น - กลับสู่หน้าหลัก
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <p class="card-title-desc">รายการสินค้า</p>
            </div>
            <div class="col-md-6"></div>
          </div>
          <b-tabs
            justified
            nav-class="nav-tabs-custom"
            content-class="p-3 text-muted"
          >
            <b-tab active @click="changeType('ACCY')">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block">อุปกรณ์เสริม</span>
              </template>
              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">รหัสอะไหล่</th>
                        <th scope="col">ประเภท</th>
                        <th scope="col">ชื่ออะไหล่</th>
                        <th scope="col">ราคา/หน่วย</th>
                        <th scope="col">จำนวน</th>
                        <th scope="col" class="text-end">รวม</th>
                        <th scope="col">ส่วนลด(%)</th>
                        <th scope="col">ส่วนลดยอด</th>
                        <th scope="col" class="text-end">หลังลด</th>
                        <!-- <th scope="col" class="text-end">vat (%)</th>
                            <th scope="col" class="text-end">vat</th> -->
                        <!-- <th scope="col" class="text-end">สุทธิ</th> -->
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="qnAccy.length > 0">
                      <tr v-for="(anAccyItem, index) in qnAccy" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ anAccyItem.partCode }}</td>
                        <td>{{ anAccyItem.type }}</td>
                        <td>{{ anAccyItem.partNameTh }}</td>

                        <td class="number">
                          <input
                            v-model="anAccyItem.pricePerOne"
                            class="form-control text-end"
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>

                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            v-model="anAccyItem.amount"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td class="text-end">{{ anAccyItem.totalPrice }}</td>
                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            v-model="anAccyItem.dcPer"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.dcPrice }}
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.netPrice }}
                        </td>

                        <td>
                          <ul class="list-inline mb-0 action">
                            <li class="list-inline-item">
                              <a
                                v-if="statusQn == 'DFT'"
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                title="Delete"
                                @click="ConfirmDelAccy(qnDetails, index)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <span
                    v-if="qnAccy.length === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label for="accyCode"
                      ><code>*</code> ค้นหารอุปกรณ์เสริม ,ของแถม</label
                    >
                    <multiselect
                      @remove="accyCode = []"
                      :disabled="statusQn != 'DFT'"
                      id="accyCode"
                      v-model="accyCode"
                      :options="rowsAccy"
                      label="partId"
                      :custom-label="customLabelAccy"
                      :show-labels="false"
                      track-by="nameTh"
                      @input="showModal(accyCode)"
                      :close-on-select="true"
                      :internal-search="true"
                      @search-change="getAccy"
                    >
                    </multiselect>

                    <!-- @ok="handleOk" -->
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab @click="changeType('PM')">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">ของแถม</span>
              </template>
              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">รหัสอะไหล่</th>
                        <th scope="col">ประเภท</th>
                        <th scope="col">ชื่ออะไหล่</th>
                        <th scope="col">ราคา/หน่วย</th>
                        <th scope="col">จำนวน</th>
                        <th scope="col" class="text-end">รวม</th>
                        <th scope="col">ส่วนลด(%)</th>
                        <th scope="col">ส่วนลดยอด</th>
                        <th scope="col" class="text-end">หลังลด</th>
                        <!-- <th scope="col" class="text-end">vat (%)</th>
                            <th scope="col" class="text-end">vat</th> -->
                        <!-- <th scope="col" class="text-end">สุทธิ</th> -->
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="qnPm.length > 0">
                      <tr v-for="(anAccyItem, index) in qnPm" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ anAccyItem.partCode }}</td>
                        <td>{{ anAccyItem.type }}</td>
                        <td>{{ anAccyItem.partNameTh }}</td>

                        <td class="number">
                          <input
                            v-model="anAccyItem.pricePerOne"
                            class="form-control text-end"
                            disabled
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>

                        <td class="Dc">
                          <input
                            disabled
                            v-model="anAccyItem.amount"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td class="text-end">{{ anAccyItem.totalPrice }}</td>
                        <td class="Dc">
                          <input
                            disabled
                            v-model="anAccyItem.dcPer"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.dcPrice }}
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ parseFloat(0.0) }}
                        </td>

                        <td>
                          <ul class="list-inline mb-0 action">
                            <li class="list-inline-item">
                              <a
                                v-if="statusQn == 'DFT'"
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                title="Delete"
                                @click="ConfirmDelAccy(qnDetails, index)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <span
                    v-if="qnAccy.length === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label for="accyCode"
                      ><code>*</code> ค้นหารอุปกรณ์เสริม ,ของแถม</label
                    >
                    <multiselect
                      :disabled="statusQn != 'DFT'"
                      id="accyCode"
                      v-model="accyCode"
                      :options="rowsAccy"
                      label="partId"
                      :custom-label="customLabelAccy"
                      :show-labels="false"
                      track-by="nameTh"
                      @input="showModal(accyCode)"
                      :close-on-select="true"
                      :internal-search="true"
                      @search-change="getAccy"
                    >
                    </multiselect>

                    <!-- @ok="handleOk" -->
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab @click="changeType('OTH')">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">ค่าใช้จ่ายอื่นๆ </span>
              </template>
              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">รหัสค่าใช้จ่าย</th>
                        <th scope="col">ประเภท</th>
                        <th scope="col">ชื่ออะไหล่</th>
                        <th scope="col">ราคา/หน่วย</th>
                        <th scope="col">จำนวน</th>
                        <th scope="col" class="text-end">รวม</th>
                        <th scope="col">ส่วนลด(%)</th>
                        <th scope="col">ส่วนลดยอด</th>
                        <th scope="col" class="text-end">หลังลด</th>
                        <!-- <th scope="col" class="text-end">vat (%)</th>
                            <th scope="col" class="text-end">vat</th> -->
                        <!-- <th scope="col" class="text-end">สุทธิ</th> -->
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="qnOth.length > 0">
                      <tr v-for="(anAccyItem, index) in qnOth" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ anAccyItem.extraCode }}</td>
                        <td>{{ anAccyItem.type }}</td>
                        <td>{{ anAccyItem.extraNameTh }}</td>

                        <td class="number">
                          <input
                            v-model="anAccyItem.pricePerOne"
                            class="form-control text-end"
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice,
                                anAccyItem.extraId
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>

                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            v-model="anAccyItem.amount"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice,
                                anAccyItem.extraId
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td class="text-end">{{ anAccyItem.totalPrice }}</td>
                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            v-model="anAccyItem.dcPer"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice,
                                anAccyItem.extraId
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.dcPrice }}
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.netPrice }}
                        </td>

                        <td>
                          <ul class="list-inline mb-0 action">
                            <li class="list-inline-item">
                              <a
                                v-if="statusQn == 'DFT'"
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                title="Delete"
                                @click="ConfirmDelAccy(qnDetails, index)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <span
                    v-if="qnAccy.length === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label for="accyCode"
                      ><code>*</code> ค้นหารายการค่าใช้จ่ายอื่นๆ
                    </label>
                    <multiselect
                      :disabled="statusQn != 'DFT'"
                      id="accyCode"
                      v-model="exCode"
                      :options="rowsExtra"
                      label="nameTh"
                      :show-labels="false"
                      track-by="nameTh"
                      @input="showModalEx(exCode)"
                      :custom-label="customLabelEx"
                      :close-on-select="true"
                      :internal-search="true"
                      @search-change="getExtra"
                    >
                    </multiselect>
                    <!-- @ok="handleOk" -->
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="mb-3 position-relative">
                    <label for="">&nbsp;</label><br />
                    <button
                      class="btn btn-primary"
                      @click="$router.push('/extra-service')"
                    >
                      เพิ่มค่าใช้จ่ายอื่นๆ
                    </button>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>

          <div class="card-body">
            <h4 class="card-title">
              <h4>
                <strong>สรุปยอด</strong>
              </h4>
            </h4>
            <hr />
            <div class="row">
              <b>ยอดรวมค่าสินค้า</b>
              <div class="col-md-3">
                <div class="mb-3 position-relative">
                  ราคาสินค้า (บาท) :
                  <input
                    :disabled="statusQn != 'DFT'"
                    type="number"
                    style="text-align:right"
                    v-model="vehicleTotalPrice"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.vehicleTotalPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleTotalPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleTotalPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="mb-3 position-relative">
                  ส่วนลด (%) :
                  <input
                    :disabled="statusQn != 'DFT'"
                    type="number"
                    @change="calTotal()"
                    style="text-align:right"
                    v-model="vehicleDcPer"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.vehicleDcPer.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleDcPer.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleDcPer.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="mb-3 position-relative">
                  ส่วนลด (บาท) :
                  <input
                    disabled
                    type="number"
                    style="text-align:right"
                    v-model="vehicleDcPrice"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.vehicleDcPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleDcPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleDcPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="mb-3 position-relative">
                  สุทธิ (บาท) :
                  <input
                    :disabled="statusQn != 'DFT'"
                    type="number"
                    style="text-align:right"
                    v-model="vehicleNetPrice"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.vehicleNetPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleNetPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleNetPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <b>ยอดรวมค่าอุปกรณ์เสริม</b>
              <div class="col-md-3">
                <div class="mb-3 position-relative">
                  ยอดรวมค่าอุปกรณ์เสริม (บาท) :
                  <input
                    :disabled="statusQn != 'DFT'"
                    type="number"
                    style="text-align:right"
                    v-model="ttAccy"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.ttAccy.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.ttAccy.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.ttAccy.required">{{ errormessage }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="mb-3 position-relative">
                  ส่วนลด (%) :
                  <input
                    :disabled="statusQn != 'DFT'"
                    type="number"
                    @change="calTotal()"
                    style="text-align:right"
                    v-model="partDcPer"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.partDcPer.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.partDcPer.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.partDcPer.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="mb-3 position-relative">
                  ส่วนลด (บาท) :
                  <input
                    disabled
                    type="number"
                    style="text-align:right"
                    v-model="partDcPrice"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.partDcPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.partDcPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.partDcPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="mb-3 position-relative">
                  สุทธิ (บาท) :
                  <input
                    :disabled="statusQn != 'DFT'"
                    type="number"
                    style="text-align:right"
                    v-model="partNetPrice"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.partNetPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.partNetPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.partNetPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <b>ยอดรวมค่าใช้จ่ายอื่น ๆ </b>
              <div class="col-md-3">
                <div class="mb-3 position-relative">
                  ยอดรวมค่าใช้จ่ายอื่น ๆ (บาท) :
                  <input
                    :disabled="statusQn != 'DFT'"
                    type="number"
                    style="text-align:right"
                    v-model="ttOTH"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.ttOTH.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.ttOTH.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.ttOTH.required">{{ errormessage }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="mb-3 position-relative">
                  ส่วนลด (%) :
                  <input
                    :disabled="statusQn != 'DFT'"
                    type="number"
                    @change="calTotal()"
                    style="text-align:right"
                    v-model="extraDcPer"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.extraDcPer.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.extraDcPer.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.extraDcPer.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="mb-3 position-relative">
                  ส่วนลด (บาท) :
                  <input
                    disabled
                    type="number"
                    style="text-align:right"
                    v-model="extraDcPrice"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.extraDcPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.extraDcPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.extraDcPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="mb-3 position-relative">
                  สุทธิ (บาท) :
                  <input
                    :disabled="statusQn != 'DFT'"
                    type="number"
                    style="text-align:right"
                    v-model="extraNetPrice"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.extraNetPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.extraNetPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.extraNetPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-md-2">
                <b
                  >รายละเอียด <br />
                  ใบเสนอขายรถ</b
                >
              </div>
              <div class="col-md-2">
                <div style="display: inline-block">
                  <div class="text-end">
                    ยอดรวม : <br />
                    ส่วนลดรวม(%) : <br />
                    ส่วนลดรวม(บาท) : <br />
                    ภาษีรวม(%) : <br />
                    ภาษีรวม(บาท) : <br />
                    สุทธิ : <br />
                    เงินดาวน์ : <br />
                    ยอดจัดไฟแนนซ์ :
                  </div>
                </div>
              </div>
              <div class="col-md-2 text-end">
                {{
                  Number(qnTotalPrice)
                    .toFixed(2)
                    .toLocaleString()
                }}
                <br />
                {{ Number(qnDcPer).toFixed(2) }}<br />
                {{
                  Number(qnDcPrice)
                    .toFixed(2)
                    .toLocaleString()
                }}
                <br />
                {{ parseFloat(Number(vatPer)).toFixed(2) }} <br />
                {{ Number(qnVatPrice).toLocaleString() }} <br />
                {{ Number(parseFloat(qnGrandTotalPrice)).toLocaleString() }}
                <br />
                {{ Number(downPayment).toLocaleString() }} <br />
                {{ Number(totalFinancing).toLocaleString() }}
              </div>
              <div class="col-md-1 text-end">
                บาท<br />
                %<br />
                บาท <br />
                % <br />
                บาท <br />
                บาท <br />
                บาท <br />
                บาท
              </div>
              <!-- มูลค่ารวม -->
              <div
                class="col-md-5 bg-dark text-white m-0"
                style="border-radius:10px;margin:auto;justify "
              >
                <div class="row align-items-center m-0">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-3">
                        มูลค่ารวม :
                      </div>
                      <div class="col-md-6 font-size-24">
                        {{
                          Number(
                            parseFloat(this.qnGrandTotalPrice)
                          ).toLocaleString()
                        }}
                      </div>
                      <div class="col-md-3">
                        บาท
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br /><br />
            <div class="row">
              <hr />
              <div class="text-end">
                <code v-show="statusQn != 'DFT'"
                  >ไม่สามารถบันทึกได้เนื่องจากอยู่ในสถานะยืนยันแล้ว
                </code>
                <button
                  class="btn btn-success"
                  @click="confirmPutstatusQn()"
                  :disabled="statusQn != 'DFT'"
                >
                  ยืนยันใบเสนอราคาขาย
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>

    <!-- //? Modal select Part -->
    <b-modal ref="modalAmout" title="ระบุจำนวน" hide-footer size="sm" centered>
      <div class="row">
        <div class="col">
          <div class="mb-3 position-relative">
            <label for="amount"><code>*</code> จำนวน</label>
            <span>({{ this.part.nameTh }})</span>
            <input
              :state="nameState"
              id="amount"
              v-model="amountAccy"
              type="number"
              style="text-align: center"
              class="form-control"
              value="Otto"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-11" v-show="false">
          <div class="mb-3 position-relative">
            <label for="amount"><code>*</code> %ส่วนลด</label>
            <input
              disabled
              :state="nameState"
              id="amount"
              v-model="dcPerAccy"
              type="number"
              style="text-align: center"
              class="form-control"
              value="Otto"
            />
          </div>
        </div>
        <div class="col-md-1" v-show="false">
          &nbsp; <br />
          <br />
          %
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <button @click="postAccy(part)" class="btn btn-primary">
              เพิ่ม
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end model -->

    <!-- //? Modal select Part -->
    <b-modal
      ref="modalAmoutEx"
      title="ระบุจำนวน"
      hide-footer
      size="sm"
      centered
    >
      <div class="row">
        <div class="col">
          <div class="mb-3 position-relative">
            <label for="amount"><code>*</code> จำนวน</label>
            <span>({{ this.ex.nameTh }})</span>
            <input
              :state="nameState"
              id="amount"
              v-model="amountAccy"
              type="number"
              style="text-align: center"
              class="form-control"
              value="Otto"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-11" v-show="false">
          <div class="mb-3 position-relative">
            <label for="amount"><code>*</code> %ส่วนลด</label>
            <input
              disabled
              :state="nameState"
              id="amount"
              v-model="dcPerAccy"
              type="number"
              style="text-align: center"
              class="form-control"
              value="Otto"
            />
          </div>
        </div>
        <div class="col-md-1" v-show="false">
          &nbsp; <br />
          <br />
          %
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <button @click="postEx(ex)" class="btn btn-primary">
              เพิ่ม
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end model -->

    <b-modal
      ref="modalProd"
      id="modalProd"
      title="เลือกสินค้า"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <input
              class="form-control"
              type="text"
              v-model="searchProdcode"
              placeholder="รหัสสินค้า"
            /><br />
          </div>

          <div class="col-md-4">
            <input
              class="form-control"
              type="text"
              v-model="searchNameProd"
              placeholder="ชื่อสินค้า"
            /><br />
          </div>
          <div class="col-md-2">
            <button class="btn btn-primary" @click="getProduct()">
              ค้นหา
            </button>
          </div>
        </div>
        <div class="row">
          <div class="table-responsive mb-0">
            <b-table
              :items="rowProducts"
              :fields="fieldProds"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              select-mode="single"
              @row-selected="onRowSelectedProd"
            >
            <!-- @row-selected="onRowSelectedbooking" -->
              <!-- <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template> -->

              <!-- End edit table -->
            </b-table>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
